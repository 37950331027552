<template>
    <div class="fill-height" v-bind:style="style">
        <v-container class="fill-height">
            <v-row align="center" justify="center">
                <v-col cols="12" xl="4">
                    <v-card class="axessia-card fill-height" outlined>
                        <v-toolbar class="mb-0 ma-4" dense flat>
                            <v-toolbar-title class="primary--text title">
                                Réinitialisation de mot de passe
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="pb-0">
                            <ValidationObserver ref="observer">
                                <ValidationProvider ref="passwordInput" v-slot="{ errors }" name="Client" rules="required" vid="confirmInput">
                                    <v-text-field v-model="password" label="Mot de passe" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword" :error-messages="errors" outlined dense>
                                        <template v-slot:append>
                                            <v-icon tabindex="-1" @click="showPassword = !showPassword"> {{ showPassword ? 'fas fa-eye' : 'fas fa-eye-slash' }} </v-icon>
                                        </template>
                                    </v-text-field>
                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors }" name="Client" rules="required|confirmed:confirmInput">
                                    <v-text-field v-model="passwordConfirmation" label="Confirmation" :type="showPasswordConfirmation ? 'text' : 'password'" :error-messages="errors" outlined dense>
                                        <template v-slot:append>
                                            <v-icon tabindex="-1" @click="showPasswordConfirmation = !showPasswordConfirmation"> {{ showPasswordConfirmation ? 'fas fa-eye' : 'fas fa-eye-slash' }} </v-icon>
                                        </template>
                                    </v-text-field>
                                </ValidationProvider>
                            </ValidationObserver>
                        </v-card-text>

                        <v-card-actions class="pt-0">
                            <v-btn @click="submit()" class="ml-2" color="primary" small depressed> Confirmer </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Alerts',

    data: () => ({
        password: '',
        passwordConfirmation: '',

        showPassword: false,
        showPasswordConfirmation: false
    }),

    methods: {
        async submit() {
            try {
                const valid = await this.$refs.observer.validate();
                if (!valid) {
                    return;
                }
                this.setLoading(true);

                const body = {
                    type: 'buyer',
                    password: this.password,
                    token: this.$route.query.token,
                    providerCode: this.$store.state.application.providerCode
                };
                const { success, err } = await this.repos.auth.resetPassword(body);
                if (success) {
                    this.$notify({
                        title: 'Information',
                        text: 'Votre mot de passe à bien été modifié. Vous allez être redirigé',
                        type: 'success'
                    });
                    setTimeout(() => {
                        this.$router.push('/connexion');
                    }, 3000);
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: err.message,
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        },

        async checkToken(token) {
            try {
                this.setLoading(true);

                const query = {
                    token,
                    providerCode: this.$store.state.application.providerCode
                };
                const { success } = await this.repos.auth.checkToken(query);
                if (!success) {
                    this.$router.push('/connexion');
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: err.message,
                    type: 'error'
                });
                this.$router.push('/connexion');
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        style() {
            const style = {};
            let image = null;

            switch (this.$store.state.application.providerCode) {
                case 'europeanhomes':
                    image = require('@/assets/europeanhomes/homepage.png');
                    break;
            }

            if (image !== null && !this.$vuetify.breakpoint.mobile) {
                style.backgroundImage = `url("${image}")`;
                style.backgroundSize = 'contain';
                style.backgroundPosition = 'center';
            }

            return style;
        }
    },

    created() {
        this.checkToken(this.$route.query.token);
    }
};
</script>
